import React from 'react';
import { graphql } from 'gatsby';
import CardList, { CardListFragment } from '../components/CardList';
import Layout from '../components/Layout';
import { AllMarkdownRemark } from '../types/query';

type QueryData = AllMarkdownRemark<CardListFragment>;

const IndexPage: React.FCWithQuery<QueryData> = props => {
  const { data } = props;
  return (
    <Layout showTagMenu>
      <CardList items={data.allMarkdownRemark.edges} />
    </Layout>
  );
};

export const query = graphql`
  query {
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        ...CardListFragment
      }
    }
  }
`;

export default IndexPage;
